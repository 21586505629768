import React from 'react';
import Chat from './pages/Chat/Chat';
import {createTheme, ThemeProvider} from '@mui/material';
import './languages/i18n';

function App() {
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <Chat />
    </ThemeProvider>
  );
}

export default App;
