import {useTranslation} from 'react-i18next';
import { useEffect } from 'react';

type UseSwitchLanguageType = {
  isOpenChat: boolean;
};

export const useSwitchLanguage = ({ isOpenChat }: UseSwitchLanguageType) => {
  const { i18n } = useTranslation();

  useEffect(()=>{
    const lang = localStorage.getItem('lang') || '';
    const langCodeLength = 2;

    if (!lang && lang.length !== langCodeLength) { return; }

    if (isOpenChat) {
      i18n.changeLanguage(lang);
    }
  }, [isOpenChat, i18n]);
};
