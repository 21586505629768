export const envConfig = {
  production: {
    apiUrl: 'https://formulator-online-api-prod.herokuapp.com/',
    firebase: {
      apiKey: 'AIzaSyDRqMcR3eIjsTUIXwoSPLS-rqGOYahpx-E',
      authDomain: 'formulator-online.firebaseapp.com',
      databaseURL: 'https://formulator-online.firebaseio.com',
      projectId: 'formulator-online',
      storageBucket: 'formulator-online.appspot.com',
      messagingSenderId: '1023073527919',
      appId: '1:1023073527919:web:292a08d95d026eeda1059a',
      timestampsInSnapshots: true,
    },
  },
  development: {
    apiUrl: 'https://formulator-online-api-stage.herokuapp.com/',
    firebase: {
      apiKey: 'AIzaSyClv_dpCriL3CrMnkrEeUNnEz6z1XB4_Wg',
      authDomain: 'formulator-online-stage.firebaseapp.com',
      databaseURL: 'https://formulator-online-stage.firebaseio.com',
      projectId: 'formulator-online-stage',
      storageBucket: 'formulator-online-stage.appspot.com',
      messagingSenderId: '890811321248',
      appId: '1:890811321248:web:e9d538df4af9a86f03f2c5',
    },
  },
  test: {
    apiUrl: 'https://formulator-online-api-stage.herokuapp.com/',
    firebase: {
      apiKey: 'AIzaSyClv_dpCriL3CrMnkrEeUNnEz6z1XB4_Wg',
      authDomain: 'formulator-online-stage.firebaseapp.com',
      databaseURL: 'https://formulator-online-stage.firebaseio.com',
      projectId: 'formulator-online-stage',
      storageBucket: 'formulator-online-stage.appspot.com',
      messagingSenderId: '890811321248',
      appId: '1:890811321248:web:e9d538df4af9a86f03f2c5',
    },
  },
}
