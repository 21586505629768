import {ChatMessageInterface} from '../interfaces/Chat/ChatMessage.interface';
import {Howl} from 'howler';

export const playIncomeMessageSound = (messages: ChatMessageInterface[], isMessagesReceived: boolean) => {
  if (!messages?.length) {
    return;
  }

  const isNewAdminMessage = messages[messages.length - 1].author === 'admin';

  if (!isNewAdminMessage || !isMessagesReceived) {
    return;
  }

  const incomeMessageSoundLink = 'https://formulator-online-api-prod.herokuapp.com/assets/support-chat/mp3/income-message.mp3';
  const sound = new Howl({
    src: [incomeMessageSoundLink]
  });

  sound.play();
};
