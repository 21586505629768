import React, { FormEvent, useState } from 'react';
import {DomainNameEnum} from '../../enums/domain-name.enum';
import { Box, TextField, Typography } from '@mui/material';
import {socialLinks} from '../../consts/social-links.const';
import {ColorEnum} from '../../enums/color.enum';
import getDatabase from '../../firebase';
import {useTranslation} from 'react-i18next';
import { LocalStorageEnum } from '../../enums/local-storage.enum';
import moment from "moment/moment";

const linkStyle = {
  color: ColorEnum.white,
  '&:focus': {
    color: ColorEnum.white,
  },
  '&:visited': {
    color: ColorEnum.white,
  },
  background: ColorEnum.mainGreen,
  lineHeight: 0,
  padding: '8px',
  borderRadius: '100%',
};

interface ChatNameFormInterface {
  setSupportKey(key: string): void;
}

function ChatNameForm({setSupportKey}: ChatNameFormInterface) {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const startChat = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const trimmedName = name.trim();

    if (!trimmedName || loading) {
      return;
    }

    setLoading(true);

    const db = getDatabase().ref(DomainNameEnum.formulatorSupportChat).child(DomainNameEnum.chats);
    const userInfo = localStorage.getItem(LocalStorageEnum.user);
    const userEmail = userInfo && JSON.parse(userInfo).email;

    const formulatorSupportChat = await db.push(
      {
        userName: trimmedName,
        dateOfCreation: moment().format(),
        email: userEmail,
      },
      (error: Error | null) => {
        if (!error) {
          setName('');
        }
        setLoading(false);
      }
    ).get();

    setSupportKey(formulatorSupportChat.key || '');
  }

  return (
    <Box
      component="form"
      sx={{
        width: '100%',
      }}
      onSubmit={startChat}
    >
      <Box
        sx={{
          padding: '16px',
        }}
      >
        <Box
          sx={{
            boxShadow: 5,
            borderRadius: '10px',
            padding: '16px',
          }}
        >
          <Typography
            sx={{
              paddingBottom: '8px',
              color: ColorEnum.mainBlack,
              fontSize: '17px',
              fontWeight: 'bold',
            }}
          >{t('CHAT.START_A_CONVERSATION')}</Typography>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <img
              style={{
                borderRadius: '100px',
                overflow: 'hidden',
              }}
              src="https://formulator-online-api-prod.herokuapp.com/assets/support-chat/images/admin-avatar.jpeg"
              width="50"
              height="50"
              alt="Logo"
            />
            <Typography
              sx={{
                color: ColorEnum.darkGray,
                marginLeft: '16px',
                fontSize: '14px',
                alignSelf: 'center',
              }}
            >{t('CHAT.I_LL_REPLY_AS_SOON_AS_I_CAN')}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              marginTop: '8px',
              fontSize: '14px',
            }}
          >
            <TextField
              name="name"
              required
              fullWidth
              id="name"
              label={t('CHAT.NAME')}
              autoFocus
              onChange={(event) => setName(event.target.value)}
              value={name}
              variant='standard'
              sx={{
                width: 'calc(100% - 138px)',
                paddingRight: '8px',
                '#name-label': {
                  fontSize: '14px',
                },
                '#name': {
                  fontSize: '15px',
                },
              }}
            />
            <Box
              component="button"
              sx={{
                cursor: 'pointer',
                background: ColorEnum.mainGreen,
                color: ColorEnum.white,
                borderRadius: '20px',
                padding: '6px 12px',
                fontSize: '13px',
                width: '139px',
                textAlign: 'center',
                marginTop: '18px',
                border: 'none',
              }}
            >{t('CHAT.START_CHAT')}</Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          padding: '16px',
        }}
      >
        <Box
          sx={{
            boxShadow: 5,
            borderRadius: '10px',
            padding: '16px',
          }}
        >
          <Box
            sx={{
              paddingBottom: '8px',
              color: ColorEnum.mainBlack,
              fontSize: '15px',
              fontWeight: 'bold',
            }}
          >
            {t('CHAT.YOU_CAN_CONTACT_US_VIA_MESSENGERS')}
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
              justifyContent: 'center',
            }}
          >
            {socialLinks.map(linkInfo =>
              <Box
                component="a"
                href={linkInfo.link}
                key={linkInfo.link}
                target="_blank"
                sx={linkStyle}
              >{linkInfo.icon}</Box>)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ChatNameForm;
