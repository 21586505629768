import React, {useRef, useState} from 'react';
import {DomainNameEnum} from '../../enums/domain-name.enum';
import {ChatMessageInterface} from '../../interfaces/Chat/ChatMessage.interface';
import {Box, Button, TextField} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import getDatabase from '../../firebase';
import {useTranslation} from 'react-i18next';
import moment from "moment/moment";

interface ChatMessageFormInterface {
  supportKey: string;
}

const ENTER_KEYBOARD_KEY = 13;

function ChatMessageForm({supportKey}: ChatMessageFormInterface) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [text, setText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const sendMessage = async () => {
    const trimmedText = text.trim();

    if (!trimmedText) {
      return;
    }

    setLoading(true);

    const db = getDatabase().ref(`${DomainNameEnum.formulatorSupportChat}/${DomainNameEnum.chats}/${supportKey}/${DomainNameEnum.messages}`);

    await db.push(
      {
        text: trimmedText,
        author: 'user',
        dateOfCreation: moment().format(),
        isSentToTelegram: false,
      } as ChatMessageInterface,
      (error) => {
        if (!error) {
          setText('');
        }
        setLoading(false);
      }
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.which === ENTER_KEYBOARD_KEY && inputRef.current && !loading) {
      event.preventDefault();
      inputRef.current.getElementsByTagName("textarea")[0].style.height = "auto";
      sendMessage();
    }
  };

  return (
    <Box
      component="form"
      sx={{
        width: '100%',
        marginTop: 'auto',
        padding: '16px',
        display: 'flex',
        boxSizing: 'border-box',
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        ref={inputRef}
        name="text"
        onChange={(event) => setText(event.target.value)}
        value={text}
        variant='standard'
        minRows={2}
        maxRows={2}
        sx={{
          width: 'calc(100% - 40px)',
          'textarea': {
            fontSize: '14px',
            paddingBottom: '8px',
          },
        }}
        placeholder={`${t('CHAT.WRITE_A_MESSAGE')}...`}
        autoComplete="off"
        multiline
        onKeyDown={handleKeyDown}
      />

      <Button
        type='button'
        onClick={sendMessage}
        disabled={loading}
      >
        <SendIcon
          fontSize="small"
          sx={{
            fontSize: '20px',
          }}
        />
      </Button>
    </Box>
  );
}

export default ChatMessageForm;
