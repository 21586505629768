import React, {useCallback, useEffect, useRef, useState} from 'react';
import ChatForm from "../../components/Chat/ChatForm";
import {Box, Container, Typography, useMediaQuery} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {ColorEnum} from '../../enums/color.enum';
import {ChatInterface} from '../../interfaces/Chat/Chat.interface';
import {playIncomeMessageSound} from '../../helpers/messagesHelper';
import firebaseIn from 'firebase/compat';
import {LocalStorageEnum} from '../../enums/local-storage.enum';
import {ChatMessageInterface} from '../../interfaces/Chat/ChatMessage.interface';
import {DomainNameEnum} from '../../enums/domain-name.enum';
import ChatIcon from '@mui/icons-material/Chat';
import firebase from "firebase/compat/app";
import getDatabase from '../../firebase';
import {mdSize} from '../../consts/md-size.const';
import { useSwitchLanguage } from '../../hooks';
import { useTranslation } from 'react-i18next';
import moment from "moment";

function Chat() {
  const [isOpenChat, setIsOpenChat] = useState<boolean>(false);
  const [supportChat, setSupportChat] = useState<ChatInterface | null>(null);
  const [supportKey, setSupportKey] = useState<string>('');
  const [isInitApp, setIsInitApp] = useState<boolean>(false);
  const [isUnreadMessagesExist, setIsUnreadMessagesExist] = useState<number>(0);
  const receivedMessages = useRef<ChatMessageInterface[]>([]);
  const previousReceivedMessages = useRef<ChatMessageInterface[]>([]);
  const isMd = useMediaQuery(mdSize);
  const { t } = useTranslation();
  useSwitchLanguage({ isOpenChat });

  const playIncomeMessageSoundCallback = useCallback(playIncomeMessageSound, []);

  const onChatInfoValue = useCallback((snapshot: firebaseIn.database.DataSnapshot) => {
    if (!snapshot.exists()) {
      setSupportChat(null);
      localStorage.removeItem(LocalStorageEnum.supportKey);
      return;
    }
    const supportChat = snapshot.val();
    supportChat.messages = supportChat.messages && Object.keys(supportChat.messages).map(messageKey => ({
      key: messageKey,
      ...supportChat.messages[messageKey],
    }));
    supportChat.messages = supportChat.messages && supportChat.messages
      .sort((firstMessage: ChatMessageInterface, secondMessage: ChatMessageInterface) =>
        moment(firstMessage.dateOfCreation).isAfter(moment(secondMessage.dateOfCreation))
      )
    setSupportChat(supportChat);

    playIncomeMessageSoundCallback(supportChat.messages, Boolean(receivedMessages.current?.length));
    receivedMessages.current = supportChat.messages;
  }, [playIncomeMessageSoundCallback]);

  useEffect(() => {
    const supportKey = localStorage.getItem(LocalStorageEnum.supportKey);
    supportKey && setSupportKey(supportKey);
  }, []);

  useEffect(() => {
    let db: firebase.database.Reference;
    setTimeout(() => {
      setIsInitApp(true);
      db = getDatabase().ref(`${DomainNameEnum.formulatorSupportChat}/${DomainNameEnum.chats}/${supportKey}`);

      if (supportKey) {
        localStorage.setItem(LocalStorageEnum.supportKey, supportKey);

        db.on('value', onChatInfoValue);
      }
    }, 2000);

    return () => {
      db?.off('value', onChatInfoValue);
    }
  }, [supportKey, onChatInfoValue, setIsInitApp]);

  useEffect(() => {
    if (!previousReceivedMessages.current.length) {
      previousReceivedMessages.current = supportChat?.messages || [];
    }

    const supportChatMessagesLength = supportChat?.messages?.length || 0;
    const differenceNumber = supportChatMessagesLength - previousReceivedMessages.current.length;
    const trimmedDifferenceNumber = differenceNumber > 98 ? 99 : differenceNumber;

    if (!isOpenChat) {
      setIsUnreadMessagesExist(trimmedDifferenceNumber);
    } else {
      setIsUnreadMessagesExist(0);
    }
  }, [isOpenChat, supportChat, previousReceivedMessages]);

  useEffect(() => {
    if (isOpenChat) {
      setIsUnreadMessagesExist(0);
      previousReceivedMessages.current = [];
    }
  }, [isOpenChat]);

  const onCloseChat = () => {
    setIsUnreadMessagesExist(0);
    previousReceivedMessages.current = [];
    setIsOpenChat(false);
  };

  const wrapperPositionDesktopStyles = {
    right: isOpenChat ? '20px' : '-420px',
    bottom: isOpenChat ? '20px' : '-420px',
    width: '350px',
    height: '500px',
  };

  const wrapperPositionMobileStyles = {
    right: isOpenChat ? '0' : '-600px',
    bottom: isOpenChat ? '0' : '-600px',
    width: '100%',
    height: '100%',
    overflow: 'scroll',
  };

  const wrapperPositionStyles = isMd ? wrapperPositionMobileStyles : wrapperPositionDesktopStyles;

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        position: "relative",
        zIndex: 99999,
      }}
    >
      <Box
        sx={{
          marginTop: '16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '20px',
          boxShadow: 5,
          position: 'fixed',
          transition: 'all 0.3s ease',
          zIndex: 2,
          background: '#fff',
          ...wrapperPositionStyles,
        }}
      >
        <Box
          sx={{
            width: '100%',
            textAlign: 'right',
            position: 'absolute',
            right: '15px',
            top: '16px',
          }}
        >
          <CloseIcon
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
              color: ColorEnum.white,
              fontSize: '24px',
            }}
            onClick={onCloseChat}
          />
        </Box>
        <Box
          component="div"
          sx={{
            width: '100%',
            padding: '16px',
            margin: 0,
            background: ColorEnum.mainGreen,
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            boxSizing: 'border-box',
          }}
        >
          <Typography component="p" color={ColorEnum.white} fontWeight="bold" fontSize="17px">
            Formulator Online {t('CHAT.CHAT_TITLE')}
          </Typography>
          <Typography component="p" color={ColorEnum.white} fontSize="14px">
            {t('CHAT.WELCOME_DESCRIPTION')}
          </Typography>
        </Box>
        <ChatForm
          supportChat={supportChat}
          setSupportKey={setSupportKey}
          supportKey={supportKey}
        />
      </Box>
      {isInitApp ?
        <Box
          sx={{
            position: 'fixed',
            width: '60px',
            height: '60px',
            right: '20px',
            bottom: '20px',
            cursor: 'pointer',
            background: ColorEnum.mainGreen,
            borderRadius: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => setIsOpenChat(true)}
        >
          <ChatIcon
            sx={{
              color: ColorEnum.white,
              fontSize: '28px',
              position: "relative",
              top: "1px",
            }}
          />
          {isUnreadMessagesExist !== 0 && <Box
            sx={{
              width: "20px",
              height: "20px",
              position: 'absolute',
              top: '-5px',
              left: '-5px',
              background: 'red',
              borderRadius: '100%',
              fontSize: '10px',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >{isUnreadMessagesExist}</Box>}
        </Box> :
        null
      }
    </Container>
  );
}

export default Chat;
