import React, {useEffect} from 'react';
import ChatNameForm from './ChatNameForm';
import ChatMessages from './ChatMessages';
import {ChatInterface} from '../../interfaces/Chat/Chat.interface';
import ChatMessageForm from './ChatMessageForm';
import {Box} from '@mui/material';
import {ChatMessageInterface} from '../../interfaces/Chat/ChatMessage.interface';
import {DomainNameEnum} from '../../enums/domain-name.enum';
import getDatabase from '../../firebase';
import moment from "moment";

interface ChatFormInterface {
  supportChat: ChatInterface | null;
  supportKey: string;
  setSupportKey: (key: string) => void;
}

function ChatForm({supportChat, setSupportKey, supportKey}: ChatFormInterface) {
  useEffect(() => {
    const fiveMinutes = 300000;
    const timeoutId = setTimeout(async () => {
      const isAdminSentMessage = supportChat?.messages?.find(message => message.author === 'admin');

      if (isAdminSentMessage) {
        return;
      }

      const db = getDatabase().ref(`${DomainNameEnum.formulatorSupportChat}/${DomainNameEnum.chats}/${supportKey}/${DomainNameEnum.messages}`);

      await db.push(
        {
          text: 'CHAT.WAIT_MESSAGE',
          author: 'admin',
          dateOfCreation: moment().format(),
          isSentToTelegram: true,
        } as ChatMessageInterface,
      );
    }, fiveMinutes);

    return () => {
      clearTimeout(timeoutId);
    };
  });

  return (
    <>
      <Box sx={{
        display: supportChat ? 'none' : 'flex',
      }}>
        <ChatNameForm setSupportKey={setSupportKey}/>
      </Box>
      {supportChat ?
        <ChatMessages messages={supportChat.messages}/> :
        null}
      {supportChat ?
        <ChatMessageForm supportKey={supportKey}/> :
        null}
    </>
  );
}

export default ChatForm;
