import TelegramIcon from '@mui/icons-material/Telegram';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import React from 'react';

export const socialLinks = [
  {
    link: 'https://t.me/Nataliiaaaaam',
    icon: <TelegramIcon sx={{ fontSize: '24px' }} />,
  },
  {
    link: 'https://instagram.com/formulator.app?igshid=YWJhMjlhZTc=',
    icon: <InstagramIcon sx={{ fontSize: '24px' }} />,
  },
  {
    link: 'https://m.me/formulator.online',
    icon: <FacebookIcon sx={{ fontSize: '24px' }} />,
  },
];
