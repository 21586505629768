import React, {useRef} from 'react';
import {ChatMessageInterface} from '../../interfaces/Chat/ChatMessage.interface';
import {Box} from '@mui/material';
import {ADMIN_AUTHOR} from '../../consts/messagesInfo.const';
import {ColorEnum} from '../../enums/color.enum';
import {useTranslation} from 'react-i18next';
import moment from "moment/moment";

interface ChatMessagesInterface {
  messages: ChatMessageInterface[];
}

function ChatMessages({messages}: ChatMessagesInterface) {
  const messagesRef = useRef<Element | null>(null);
  const { t } = useTranslation();

  const greetingMessage = {
    key: '0',
    text: t('CHAT.HELLO'),
    author: ADMIN_AUTHOR,
    isSentToTelegram: false,
    dateOfCreation: moment().format(),
  }
  const messagesWithGreeting = messages?.length ? [greetingMessage, ...messages] : [greetingMessage];

  setTimeout(() => {
    messagesRef.current?.scrollTo({
      top: messagesRef.current.scrollHeight,
      behavior: 'smooth'
    });
  });

  const messageTemplate = messagesWithGreeting?.map(message => {
    const isUser = message.author === 'user';

    return (
      <Box
        key={message.key}
        sx={{
          marginLeft: isUser ? '16px' : 0,
          marginRight: isUser ? 0 : '16px',
          marginBottom: '8px',
          backgroundColor: isUser ? ColorEnum.mainGreen : ColorEnum.mainGray,
          borderRadius: '4px',
          padding: '8px',
          color: isUser ? ColorEnum.mainGray : ColorEnum.mainBlack,
          whiteSpace: 'break-spaces',
        }}
      >
        {t(message.text)}
      </Box>
    );
  });

  return (
    <Box
      sx={{
        width: '100%',
        overflowX: 'scroll',
        paddingTop: '16px',
        paddingLeft: '16px',
        paddingRight: '16px',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
      }}
      ref={messagesRef}
    >
      {
        messageTemplate ?
          messageTemplate :
          null
      }
    </Box>
  );
}

export default ChatMessages;
