import firebase from "firebase/compat/app";
import "firebase/compat/database";

import {envConfig} from "./consts/env-config.const";

let config = envConfig[process.env.NODE_ENV].firebase;

function getDatabase(): firebase.database.Database {
  if (window.firebaseDatabase) {
    return window.firebaseDatabase();
  }

  if (!firebase.apps.length) {
    firebase.initializeApp(config, 'chat');
  }

  return firebase.app('chat').database();
}

export default getDatabase;
